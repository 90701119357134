import { DateTime } from "luxon";
import {
  PremiumPathDetailCertificatesTab_ComplianceRecordFragment,
  PremiumPathDetailCertifricatesTab_ComplianceAttemptFragment,
  ComplianceSummaryFragment,
} from "@src/components/reporting/compliance/PremiumPathDetailCertificatesTab.generated";
import { orderBy } from "lodash";
import { formatDateOnlyShowYearIfNotCurrent } from "@src/utils/dates";
import { parseISO } from "date-fns";
import { ReportingLocationItem } from "@src/components/reporting/lib/types";
import { ComplianceType } from "@src/types.generated";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { isNumber } from "lodash";
import {
  FieldType,
  Operator,
  ReportFilterInput,
  ReportInput,
} from "@src/types.generated";
import { isString } from "lodash";
import {
  ReportFilterFragment,
  SavedReportFragment,
} from "@src/components/reporting/builder/ReportBuilderContainer.generated";
import { formatDateTime } from "@src/utils/dates";
import {
  ISO_8601_UNIT,
  OPERATOR_TO_LABEL,
  PERIOD_TO_LABEL,
} from "@src/components/reporting/lib/constants";

export function splitHeaderToLines(
  input: string,
  maxLengthPerLine = 18,
): string[] {
  if (input.length <= maxLengthPerLine) return [input]; // No need to split if input is within limit

  const firstCut = input.substring(0, maxLengthPerLine);
  let spaceIndex = firstCut.lastIndexOf(" ");

  if (spaceIndex === -1 || spaceIndex < 10) {
    // Avoid too short first line
    spaceIndex = maxLengthPerLine;
  }

  const firstLine = input.substring(0, spaceIndex);
  let secondLine = input.substring(spaceIndex);

  if (secondLine.length > maxLengthPerLine) {
    secondLine = secondLine.substring(0, maxLengthPerLine - 3) + "...";
  }

  return [firstLine.trim(), secondLine.trim()];
}

export function getCellColorTuple(
  percent: number,
  greenThreshold: number,
  redThreshold: number,
): [string, string] {
  if (!isNumber(percent))
    return [deprecatedTones.gray5Alpha, deprecatedTones.gray5Alpha];
  if (percent >= greenThreshold)
    return [deprecatedTones.green2, deprecatedTones.green9];
  if (percent <= redThreshold)
    return [deprecatedTones.red2, deprecatedTones.red9];
  return [deprecatedTones.gray3Alpha, deprecatedTones.gray8];
}

export const getLocationCompletionReportingRowLabel = (
  y: ReportingLocationItem,
): string => {
  let title = y.allLocations
    ? "All locations"
    : y.location?.name || y.locationGroup?.name || "";
  if (y.role) {
    title = `${title} - ${y.role.name}`;
  }
  return title;
};

export const getReportingLocationItemUserCount = (
  y: ReportingLocationItem,
): number => {
  return y.location
    ? y.location.userCount
    : y.locationGroup
      ? y.locationGroup.userCount
      : y.allLocations?.userCount || 0;
};

export const getReportingLocationItemKey = (
  y: ReportingLocationItem,
): string => {
  if (y.location) {
    return `location-${y.location.id}`;
  } else if (y.locationGroup) {
    return `locationGroup-${y.locationGroup?.id}`;
  } else {
    return "all-locations-row-key";
  }
};

export const getLocationComplianceReportingCellKey = (
  x: ComplianceType,
  y: ReportingLocationItem,
): string => `${getReportingLocationItemKey(y)}-${x}`;

export const getCertifiedBy = (
  record: PremiumPathDetailCertificatesTab_ComplianceRecordFragment,
): string => {
  const attempt = getMostRecentComplianceAttempt(record);
  if (!attempt) {
    return "";
  }
  if (attempt.importedFromExternalSource) {
    return "Import";
  } else {
    return "Assignment";
  }
};

export const getMostRecentComplianceAttempt = (
  complianceRecord: PremiumPathDetailCertificatesTab_ComplianceRecordFragment,
): PremiumPathDetailCertifricatesTab_ComplianceAttemptFragment => {
  return orderBy(complianceRecord.attempts, "createdAt", "desc")[0];
};

export const getMostRecentCompletedComplianceAttempt = (
  complianceRecord: PremiumPathDetailCertificatesTab_ComplianceRecordFragment,
): PremiumPathDetailCertifricatesTab_ComplianceAttemptFragment => {
  return orderBy(
    complianceRecord.attempts.filter(
      (c: PremiumPathDetailCertifricatesTab_ComplianceAttemptFragment) =>
        c.compliantUntil,
    ),
    "createdAt",
    "desc",
  )[0];
};

export const formatCompliantUntil = (
  compliantUntil: string | null | undefined,
): string => {
  if (!compliantUntil) return "Not compliant";
  const dateStr = formatDateOnlyShowYearIfNotCurrent(parseISO(compliantUntil));
  if (isInFuture(compliantUntil)) return dateStr;
  return `${dateStr} (expired)`;
};

export const isInFuture = (date: string | null | undefined): boolean => {
  if (!date) return false;
  return DateTime.fromISO(date).diffNow().as("days") > 0;
};

export const getCompliantPercent = (
  complianceRecords: ComplianceSummaryFragment[] | undefined,
): number | undefined => {
  if (!complianceRecords || !complianceRecords.length) return undefined;
  const compliant = complianceRecords.filter(
    (x) => isInFuture(x.compliantUntil) && x.compliantUntil,
  );
  return compliant?.length
    ? Math.round((compliant.length / complianceRecords.length) * 100)
    : 0;
};

export const roundToWholeNumberAndFormatPercent = (num: number): string => {
  let roundedNumber = Math.round(num);
  if (num < 100 && num > 99) {
    roundedNumber = 99;
  }
  return `${roundedNumber}%`;
};

export const getOption = (
  operator: Operator,
): { value: Operator; label: string } => {
  return {
    value: operator,
    label: OPERATOR_TO_LABEL[operator] || "",
  };
};

// Intentionally declaring here and not in constants
// to avoid uninitialized error
export const FIELD_TYPE_TO_OPERATORS: {
  [key in FieldType]?: { value: Operator; label: string }[];
} = {
  [FieldType.String]: [
    getOption(Operator.Contains),
    getOption(Operator.Eq),
    getOption(Operator.Neq),
  ],
  [FieldType.Number]: [
    getOption(Operator.Eq),
    getOption(Operator.Neq),
    getOption(Operator.Gt),
    getOption(Operator.Gte),
    getOption(Operator.Lt),
    getOption(Operator.Lte),
  ],
  [FieldType.Date]: [
    getOption(Operator.Eq),
    getOption(Operator.Neq),
    getOption(Operator.Gt),
    getOption(Operator.Gte),
    getOption(Operator.Lt),
    getOption(Operator.Lte),
  ],
  [FieldType.Datetime]: [
    getOption(Operator.Eq),
    getOption(Operator.Neq),
    getOption(Operator.Gt),
    getOption(Operator.Gte),
    getOption(Operator.Lt),
    getOption(Operator.Lte),
  ],
  [FieldType.StringList]: [getOption(Operator.In), getOption(Operator.Nin)],
};

export const getIso8601Duration = (value: string): number => {
  return parseInt(value.slice(1, -1), 10);
};

export const getIso8601Unit = (value: string): ISO_8601_UNIT => {
  return value.charAt(value.length - 1) as ISO_8601_UNIT;
};

export const formatISO8601RelativeDateString = (value: string): string => {
  const period = value.charAt(value.length - 1) as ISO_8601_UNIT;
  const number = parseInt(value.slice(1, -1), 10);
  return `${number} ${PERIOD_TO_LABEL[period]}${number > 1 ? "s" : ""} ago`;
};

export const isISO8601RelativeDateString = (value: string): boolean => {
  return value.startsWith("P");
};

export const getIso8601RelativeDate = (
  duration: string,
  unit: ISO_8601_UNIT,
): string => {
  return `P${duration}${unit}`;
};

export const formatCell = (value: string, type: FieldType): string => {
  if (!value) {
    return value;
  }
  if (
    [FieldType.Locations, FieldType.Roles, FieldType.Regions].includes(type)
  ) {
    return formatListCell(value);
  } else if (type === FieldType.Date) {
    try {
      return DateTime.fromISO(value, { zone: "UTC" }).toFormat("M/d/yyyy");
    } catch (e) {
      return value;
    }
  } else if (type === FieldType.Datetime) {
    try {
      const date = DateTime.fromISO(value).toJSDate();
      return formatDateTime(date, { alwaysShowYear: true });
    } catch (e) {
      return value;
    }
  } else if (type === FieldType.Number) {
    return value?.toString().includes(".")
      ? parseFloat(value).toFixed(2)
      : value;
  }
  return value;
};
export const formatListCell = (value: string): string => {
  if (!value || !isString(value)) {
    return "";
  }
  if (value.startsWith("[") && value.endsWith("]")) {
    return value.slice(1, -1).replaceAll('"', "").replaceAll("\n", "").trim();
  }
  return value;
};

export const generateSavedReportFilters = (
  savedReport: SavedReportFragment,
): ReportFilterInput[] => {
  if (!savedReport.filters) {
    return [];
  }
  return savedReport.filters.map(
    (filter: ReportFilterFragment): ReportFilterInput => {
      const locationIds = filter.value.locationFilters?.locations
        ? filter.value.locationFilters.locations.map((loc) => loc.id)
        : null;
      const locationGroupIds = filter.value.locationFilters?.locationGroups
        ? filter.value.locationFilters.locationGroups.map(
            (locGroup) => locGroup.id,
          )
        : null;
      const locationInput = filter.value.locationFilters
        ? {
            locationIds: locationIds,
            locationGroupIds: locationGroupIds,
          }
        : null;
      const roleInput = filter.value.roleFilters?.roles
        ? {
            roleIds: filter.value.roleFilters?.roles.map((role) => role.id),
          }
        : null;
      const regionInput = filter.value.regionFilters?.regions
        ? {
            regionIds: filter.value.regionFilters?.regions.map(
              (region) => region.id,
            ),
          }
        : null;

      const filterValue = {
        locationInput: locationInput,
        roleInput: roleInput,
        regionInput: regionInput,
        stringInput: filter.value.stringInput,
        listInput: filter.value.listInput,
      };

      return {
        id: filter.id,
        fieldId: filter.reportField?.id,
        operator: filter.operator,
        value: filterValue,
      };
    },
  );
};

export const generateSavedReportInput = (
  savedReport: SavedReportFragment,
): ReportInput => {
  return {
    topicId: savedReport.topic.id,
    fieldIds: savedReport.fields.map((crfm) => crfm.field.id),
    filters: generateSavedReportFilters(savedReport),
    sortBy: savedReport.sortBy?.id,
    sortAscending: savedReport.sortAscending,
  };
};
