import React from "react";
import { ListPageHeader, ListPageTitle } from "@src/components/layout/ListPage";
import { useTranslation } from "@src/utils/translationSets";
import useLanguage from "@src/hooks/useLanguage";

const YOUR_ASSIGNMENTS_ENGLISH = "Your assignments";

const MyTrainingPageHeader: React.FC = () => {
  const lang = useLanguage();
  const yourAssignmentsTranslation = useTranslation(
    YOUR_ASSIGNMENTS_ENGLISH,
    lang || "en",
  );
  return (
    <ListPageHeader>
      <ListPageTitle>
        {yourAssignmentsTranslation.text || YOUR_ASSIGNMENTS_ENGLISH}
      </ListPageTitle>
    </ListPageHeader>
  );
};

export default MyTrainingPageHeader;
