import { ComplianceType, Operator } from "@src/types.generated";

export const COMPLIANCE_TYPE_COPY: { [key in ComplianceType]: string } = {
  [ComplianceType.SexualHarassmentPrevention]: "Sexual Harassment Prevention",
  [ComplianceType.FoodHandler]: "Food Handler",
  [ComplianceType.FoodManager]: "Food Manager",
  [ComplianceType.Allertrain]: "Allertrain",
  [ComplianceType.HumanTraffickingAwarenessCa]: "Human Trafficking Awareness",
  [ComplianceType.CaWorkplaceViolencePreventionNonsupervisor]:
    "Workplace Violence Prevention Training (California) for Non-Supervisors",
  [ComplianceType.CaWorkplaceViolencePreventionSupervisor]:
    "Workplace Violence Prevention Training (California) for Supervisors",
  [ComplianceType.CaIndoorHeatIllnessPreventionNonsupervisor]:
    "Indoor Heat Illness Prevention Training (California) for Non-Supervisors",
  [ComplianceType.CaIndoorHeatIllnessPreventionSupervisor]:
    "Indoor Heat Illness Prevention Training (California) for Supervisors",
};

export const ROW_HEIGHT = 44;
export const Y_AXIS_WIDTH = 240;
export const X_AXIS_HEIGHT = 220;
export const CELL_WIDTH = 45;

export const OPERATOR_TO_LABEL: { [key in Operator]?: string } = {
  CONTAINS: "Contains",
  EQ: "Equals",
  NEQ: "Does not equal",
  GT: "Greater than",
  GTE: "Greater than or equal to",
  LT: "Less than",
  LTE: "Less than or equal to",
  IN: "In",
  NIN: "Not in",
};

export type ISO_8601_UNIT = "M" | "W" | "D" | "H" | "Y";
export const PERIOD_TO_LABEL: { [key in ISO_8601_UNIT]: string } = {
  M: "Month",
  W: "Week",
  D: "Day",
  H: "Hour",
  Y: "Year",
};
