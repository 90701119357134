import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { MyTranslationSetFragmentDoc, CoverImageFragmentDoc, MediaFragmentDoc, TrainingResourceVersionFragmentDoc, ContentSharingPermissionFragmentDoc, TranslationSetFragmentDoc, ContentTagMembershipFragmentDoc, ImageUrlsFragmentDoc, AutomationFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, ConvertMediaToRichTextDocumentWorkflowFragmentDoc, BlastSentRecordFragmentDoc, TrainingResourceFragmentDoc, LessonFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyTrainingPageMyTodoAssignmentsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyTrainingPageMyTodoAssignmentsCountQuery = { __typename?: 'Query', MyTodoAssignments: { __typename?: 'Assignments', totalCount: number } };

export type MyTrainingPageMyTodoAssignmentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyTrainingPageMyTodoAssignmentsQuery = { __typename?: 'Query', MyTodoAssignments: { __typename?: 'Assignments', objects: Array<{ __typename?: 'Assignment', id: string, status: AssignmentStatus, attemptsCount: number, accessSources: Array<{ __typename?: 'AccessSource', id: string, createdAt: string }>, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', uuid: string, myTranslation: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null, description: { __typename?: 'TranslationSet', uuid: string, myTranslation: string } }, currentCourseInstance?: { __typename?: 'CourseInstance', id: number, track: number, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null, certificateUrl?: string | null } | null, currentPathInstance?: { __typename?: 'PathInstance', id: string, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null } | null }> } };

export type MyTrainingPageToDoAssignmentFragment = { __typename?: 'Assignment', id: string, status: AssignmentStatus, attemptsCount: number, accessSources: Array<{ __typename?: 'AccessSource', id: string, createdAt: string }>, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', uuid: string, myTranslation: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null, description: { __typename?: 'TranslationSet', uuid: string, myTranslation: string } }, currentCourseInstance?: { __typename?: 'CourseInstance', id: number, track: number, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null, certificateUrl?: string | null } | null, currentPathInstance?: { __typename?: 'PathInstance', id: string, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null } | null };

export type MyTrainingPageToDoLibraryItemFragment = { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', uuid: string, myTranslation: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null, description: { __typename?: 'TranslationSet', uuid: string, myTranslation: string } };

export type MyTrainingPageToDoPathInstanceFragment = { __typename?: 'PathInstance', id: string, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null };

export type MyTrainingPageToDoCourseInstanceFragment = { __typename?: 'CourseInstance', id: number, track: number, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null, certificateUrl?: string | null };

export const MyTrainingPageToDoLibraryItemFragmentDoc = gql`
    fragment MyTrainingPageToDoLibraryItem on LibraryItem {
  id
  type
  name {
    ...MyTranslationSet
  }
  coverImage {
    ...CoverImage
  }
  description {
    ...MyTranslationSet
  }
}
    ${MyTranslationSetFragmentDoc}
${CoverImageFragmentDoc}`;
export const MyTrainingPageToDoCourseInstanceFragmentDoc = gql`
    fragment MyTrainingPageToDoCourseInstance on CourseInstance {
  id
  track
  startedAt
  completedAt
  percentComplete
  dueDate
  certificateUrl
}
    `;
export const MyTrainingPageToDoPathInstanceFragmentDoc = gql`
    fragment MyTrainingPageToDoPathInstance on PathInstance {
  id
  startedAt
  completedAt
  percentComplete
  dueDate
}
    `;
export const MyTrainingPageToDoAssignmentFragmentDoc = gql`
    fragment MyTrainingPageToDoAssignment on Assignment {
  id
  status
  attemptsCount
  accessSources {
    id
    createdAt
  }
  libraryItem {
    ...MyTrainingPageToDoLibraryItem
  }
  currentCourseInstance {
    ...MyTrainingPageToDoCourseInstance
  }
  currentPathInstance {
    ...MyTrainingPageToDoPathInstance
  }
}
    ${MyTrainingPageToDoLibraryItemFragmentDoc}
${MyTrainingPageToDoCourseInstanceFragmentDoc}
${MyTrainingPageToDoPathInstanceFragmentDoc}`;
export const MyTrainingPageMyTodoAssignmentsCountDocument = gql`
    query MyTrainingPageMyTodoAssignmentsCount {
  MyTodoAssignments {
    totalCount
  }
}
    `;

/**
 * __useMyTrainingPageMyTodoAssignmentsCountQuery__
 *
 * To run a query within a React component, call `useMyTrainingPageMyTodoAssignmentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTrainingPageMyTodoAssignmentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTrainingPageMyTodoAssignmentsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTrainingPageMyTodoAssignmentsCountQuery(baseOptions?: Apollo.QueryHookOptions<MyTrainingPageMyTodoAssignmentsCountQuery, MyTrainingPageMyTodoAssignmentsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTrainingPageMyTodoAssignmentsCountQuery, MyTrainingPageMyTodoAssignmentsCountQueryVariables>(MyTrainingPageMyTodoAssignmentsCountDocument, options);
      }
export function useMyTrainingPageMyTodoAssignmentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTrainingPageMyTodoAssignmentsCountQuery, MyTrainingPageMyTodoAssignmentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTrainingPageMyTodoAssignmentsCountQuery, MyTrainingPageMyTodoAssignmentsCountQueryVariables>(MyTrainingPageMyTodoAssignmentsCountDocument, options);
        }
export type MyTrainingPageMyTodoAssignmentsCountQueryHookResult = ReturnType<typeof useMyTrainingPageMyTodoAssignmentsCountQuery>;
export type MyTrainingPageMyTodoAssignmentsCountLazyQueryHookResult = ReturnType<typeof useMyTrainingPageMyTodoAssignmentsCountLazyQuery>;
export type MyTrainingPageMyTodoAssignmentsCountQueryResult = Apollo.QueryResult<MyTrainingPageMyTodoAssignmentsCountQuery, MyTrainingPageMyTodoAssignmentsCountQueryVariables>;
export const MyTrainingPageMyTodoAssignmentsDocument = gql`
    query MyTrainingPageMyTodoAssignments {
  MyTodoAssignments {
    objects {
      ...MyTrainingPageToDoAssignment
    }
  }
}
    ${MyTrainingPageToDoAssignmentFragmentDoc}`;

/**
 * __useMyTrainingPageMyTodoAssignmentsQuery__
 *
 * To run a query within a React component, call `useMyTrainingPageMyTodoAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTrainingPageMyTodoAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTrainingPageMyTodoAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTrainingPageMyTodoAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<MyTrainingPageMyTodoAssignmentsQuery, MyTrainingPageMyTodoAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTrainingPageMyTodoAssignmentsQuery, MyTrainingPageMyTodoAssignmentsQueryVariables>(MyTrainingPageMyTodoAssignmentsDocument, options);
      }
export function useMyTrainingPageMyTodoAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTrainingPageMyTodoAssignmentsQuery, MyTrainingPageMyTodoAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTrainingPageMyTodoAssignmentsQuery, MyTrainingPageMyTodoAssignmentsQueryVariables>(MyTrainingPageMyTodoAssignmentsDocument, options);
        }
export type MyTrainingPageMyTodoAssignmentsQueryHookResult = ReturnType<typeof useMyTrainingPageMyTodoAssignmentsQuery>;
export type MyTrainingPageMyTodoAssignmentsLazyQueryHookResult = ReturnType<typeof useMyTrainingPageMyTodoAssignmentsLazyQuery>;
export type MyTrainingPageMyTodoAssignmentsQueryResult = Apollo.QueryResult<MyTrainingPageMyTodoAssignmentsQuery, MyTrainingPageMyTodoAssignmentsQueryVariables>;